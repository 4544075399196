<template>
  <div>
    <div
      class="d-flex flex-column  bgi-size-cover bgi-no-repeat rounded-top"
      :style="{ backgroundImage: `url(${backgroundImage})` }"
    >
      <h4 class="d-flex flex-center rounded-top" style="margin-top:10px;">
        <span class="text-white">Stock Notifications</span>
        <span 
          class="btn btn-text btn-success btn-sm font-weight-bold btn-font-md ml-2"
        >
          {{this.readNew}} new
        </span>
      </h4>

      <ul
        class="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-line-transparent-white nav-tabs-line-active-border-success  px-8"
        role="tablist"
      >
        <li class="nav-item">
          <a
            v-on:click="setActiveTab"
            data-tab="0"
            class="nav-link active"
            data-toggle="tab"
            href="#"
            role="tab"
          >
            Stocks
          </a>
        </li>
       
      </ul>
    </div>

    <b-tabs class="hide-tabs" v-model="tabIndex">
      <b-tab active class="p-5">
        <perfect-scrollbar
          class="scroll pr-7 mr-n7"
          style="max-height: 40vh; position: relative;"
        >
          <template v-for="(item, i) in notificationlist">
            <!--begin::Item-->
            <div v-bind:key="i" v-bind:class="{ 'd-flex align-items-center rounded p-3 bg-light-success': isA, 'd-flex align-items-center rounded p-3 bg-light-danger': isB }" style="    margin-bottom: 15px">
              <!--begin::Symbol-->
              <div class="symbol symbol-40 mr-5">
                <span class="symbol-label">
                 <div class="navi-icon ">
                   <span class="index-image notification-image" v-loadimage="item.data.images"></span>
                  
                </div>
                 
                </span>
              </div>
              <!--end::Symbol-->
              <!--begin::Text-->
              <div class="d-flex flex-column font-weight-bold">
                <a
                  href="#"
                  class="text-dark text-hover-primary mb-1 font-size-lg"
                >
                  {{ item.data.productName }}
                </a>
                <div class="text-muted">
                    <div>Stock Quantity : {{item.data.stock_quantity}}</div>
                    <div>Minimum Quantity : {{item.data.min_quantity}}</div>
                  </div>
                 
              </div>
              <div>
                
              </div>
              <!--end::Text-->
            </div>
            <!--end::Item-->
          </template>
          <div style="text-align:center;">
              <a class="font-weight-bold" href="/notification" >All Notifications</a>
          </div>
           
        </perfect-scrollbar>
      </b-tab>


    </b-tabs>
  </div>
</template>

<style lang="scss">
/* hide default vue-bootstrap tab links */
.hide-tabs > div:not(.tab-content) {
  display: none;
}
</style>

<script>

import {getNotification} from "@/api/inventory";
export default {
  name: "KTDropdownNotification",
  data() {
    return {
       loading:false,
      images: '/ctshub/media/bg/profile-img.png',
      tabIndex: 0,
      notificationlist: [],
      readNew : '',
      activeClass : 'd-flex align-items-center rounded p-3 bg-light-danger',
      isA: true,
      isB: false
      
    };
  },
  methods: {
    setActiveTab(event) {
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(event.target.getAttribute("data-tab"));

      // set current active tab
      event.target.classList.add("active");
    },
  
     getNotification(){
      this.loading = true
      getNotification().then(response => {
        //console.log(response);
        this.notificationlist = response.data.data
        this.readNew = response.data.unread
        if((this.readNew) != 0){
            this.isA = false,
            this.isB = true
        }
        this.loading = false
      })
    },
    
  },
  
  computed: {
    backgroundImage() {
      return process.env.BASE_URL + "media/misc/bg-1.jpg";
    }
  },
  created(){
 this.getNotification();
  }
};
</script>

<style >
.notification-image img {
    width: 35px;   
    height: 40px;
}
</style>
